<template>
  <div class="pa-6 pt-10">
    <v-data-table :headers="headers" :items="articles" :search="search">
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Artigos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />

          <v-spacer />

          <v-text-field v-model="search" append-icon="mdi-magnify" label="Procurar" single-line hide-details />

          <v-spacer />
          <v-btn color="primary" dark class="mb-2" @click="dialog = true">
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon small class="mr-2" v-bind="attrs" @click="editItem(item)" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon small v-bind="attrs" @click="prepareToDelete(item)" v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-checkbox :input-value="item.active" class="primary--text" value disabled />
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" hide-overlay fullscreen persistent>
      <form-articles v-if="dialog" :populate-with="articlesToEdit" @articles-added="addArticles"
        @articles-edited="editArticles" @close-dialog="closeDialog" />
    </v-dialog>
    <delete-confirmation :visible="dialogDeleteConfirmation" @cancel="dialogDeleteConfirmation = false"
      @confirm="deleteItem(item)" />
  </div>
</template>

<script>
import ArticlesService from '../../../services/articles.service';
import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'Articles',
  components: {
    'form-articles': () => import('./Form'),
    DeleteConfirmation,
  },
  data: () => ({
    headers: [
      {
        text: 'Ativo',
        align: 'start',
        sortable: false,
        value: 'active',
      },
      {
        text: 'Título',
        sortable: false,
        value: 'headline',
      },
      {
        text: 'Autor',
        sortable: false,
        value: 'author',
      },
      { text: 'Data de Criação', value: 'createdAt', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    articles: [],
    search: '',
    dialog: false,
    articlesToEdit: {},
    dialogDeleteConfirmation: false,
    dialogDelete: true,
    deleteItemID: '',
  }),

  async created() {
    await this.findAllArticles();
  },

  methods: {
    async findAllArticles() {
      const data = await ArticlesService.getArticles();
      this.articles = data.items;
    },
    closeDialog() {
      this.dialog = false;
      this.articlesToEdit = {};
    },
    editItem(item) {
      this.articlesToEdit = item;
      this.dialog = true;
    },
    prepareToDelete(item) {
      this.dialogDeleteConfirmation = true;
      this.deleteItemID = item.id;
    },
    async deleteItem() {
      try {
        this.dialogDeleteConfirmation = false;
        const itemId = this.deleteItemID;
        await ArticlesService.deleteArticles(itemId);
        await this.findAllArticles();
        this.$toast.success('Artigo excluído com sucesso');
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    async addArticles() {
      await this.findAllArticles();
    },

    async editArticles() {
      await this.findAllArticles();
    },
  },
};
</script>
